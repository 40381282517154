import React from "react"
import Moment from "react-moment"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItemBlock from "../components/shared/ItemBlock"
import style from "./events.module.scss"

const events = [
  {
    date: new Date("May 18, 2020 21:00:00"),
    name: "The Lost Leaf",
    url: "http://www.thelostleaf.com",
    description: 'Playing "Horror Jazz" with Conor Sample\'s Band',
  },
  {
    date: new Date("June 8, 2020 21:00:00"),
    name: "The Lost Leaf",
    url: "http://www.thelostleaf.com",
    description: "RUNNING FROM BEARS!!!",
  },
]

const EventCard = ({ event }) => (
  <article className={style.eventCard}>
    <div className={style.date}>
      <span>
        <Moment format="dddd, MMMM YYYY ">{event.date}</Moment>
      </span>
      <span>
        <Moment format="h:mm A">{event.date}</Moment>
      </span>
    </div>
    <h2 className={style.name}>{event.name}</h2>
    <p>{event.description}</p>
    <p>
      <a href={event.url} target="_blank" rel="noreferrer">
        {event.url}
      </a>
    </p>
    <p>
      <iframe src={event.url} title={event.url}></iframe>
    </p>
  </article>
)

const EventsPage = () => (
  <Layout>
    <SEO title="Events" />
    <ItemBlock>
      <h1 className={style.pageHeader}>Upcoming Shows</h1>
      {events
        .sort((a, b) => b.date - a.date)
        .map(event => (
          <EventCard event={event} />
        ))}
    </ItemBlock>
  </Layout>
)

export default EventsPage
